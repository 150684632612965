<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 通用管理 | 值班通知</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <el-button type="primary" @click="rowNew">新增记录</el-button>
                        <el-button @click="calendar">查看日历</el-button>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <!-- <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input> -->
                            <el-select v-model="grid.sea.TEACHER_ID" @change="filter" clearable filterable placeholder="请选择老师">
                                <el-option v-for="item in teacherList" :key="item.Id" :value="item.Id"
                                    :label="item.Name" />
                            </el-select>
                            <el-date-picker v-model="grid.sea.START_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.END_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择结束日期">
                            </el-date-picker>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="TEACHER_NAME" label="名称" width="150" align="center" />
                            <el-table-column prop="DATE_TEXT" label="日期" width="100" align="center" />
                            <el-table-column prop="TIME_TEXT" label="时间" width="100" align="center" />
                            <el-table-column prop="CONTENT_TEXT" label="内容" width="300" align="center" />
                            <el-table-column prop="STATUS_TEXT" label="状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.STATUS==0" type="info"> {{scope.row.STATUS_TEXT}}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS==1" type="success">
                                        {{scope.row.STATUS_TEXT}}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS==2" type="danger">
                                        {{scope.row.STATUS_TEXT}}</el-tag>
                                </template>
                            </el-table-column>

                            <el-table-column label="操作" width="200">
                                <template slot-scope="scope">

                                    <el-button type="text" icon="el-icon-edit" v-if="scope.row.STATUS==0"
                                        @click="rowEdit(scope.row)">修改</el-button>
                                    <el-button type="text" icon="el-icon-delete"
                                        @click="rowDel(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <New ref="remind_new" @on-saved="filter" :teacherList="teacherList" />
            <Edit ref="remind_edit" @on-saved="filter" :data="data" />
            <Calendar ref="remind_calendar" />
        </div>
    </div>
</template>
<script>
    import Edit from "./edit";
    import New from "./new";
    import Calendar from "./calendar";
    import Wgrid from "@/components/wgrid";
    export default {
        name: "index",
        components: { Wgrid, Edit, New, Calendar },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
                teacherList: [],
                data: {},
                isChange: false
            }
        },
        created() {
            this.getList();
            this.getTeachers();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
                this.isChange = true
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                let parm = JSON.parse(JSON.stringify(this.grid.sea))
                parm.TEACHER_ID = this.grid.sea.TEACHER_ID || 0;
                this.whale.remote.getCollection({
                    url: "/api/School/FX/RemindApi/GetList",
                    data: parm,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                    }
                })
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    data: { isPT: false },
                    completed: function (its) {
                        self.teacherList = its.DATA;
                    }
                })
            },
            rowNew() {
                this.$refs.remind_new.init();
            },
            rowEdit(r) {
                console.log(r)
                this.data = r
                this.$refs.remind_edit.init();
            },

            rowDel(r) {
                this.whale.delConfirm(() => {
                    let self = this;
                    this.whale.remote.getResult({
                        url: "/api/School/FX/RemindApi/Delete",
                        data: { ID: r.ID },
                        completed: function () {
                            //self.filter();
                            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
                            self.isChange = true
                        }
                    })
                });
            },
            calendar() {
                this.$refs.remind_calendar.init(this.isChange);
                this.isChange = false
            }
        }
    }
</script>