<template>
    <el-dialog :visible.sync="sta.show" width="750px">
        <span slot="title">增加 提醒记录</span>
        <div class="row">
            <div class="col-md-12">
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>选择老师：</b></label>
                            <el-select v-model="model.TEACHER_IDS" placeholder="请选择老师" style="width: 60%" multiple filterable>
                                <el-option v-for="item in teacherList" :key="item.Id" :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>提醒内容：</b></label>
                            <el-input type="textarea" :rows="2" v-model="model.CONTENT" style="width: 60%" placeholder="{#老师姓名#}老师您好,{#提醒日期#}是您值日,请您按时到岗。" />
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>提醒时间：</b></label>
                            <el-time-picker v-model="model.TIME" value-format="HH:mm:dd" format="H点mm分"
                                style="width: 60%" placeholder="选择提醒时间">
                            </el-time-picker>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>提醒日期：</b></label>
                            <el-date-picker v-model="model.DATES" value-format="yyyy-MM-dd" type="dates"
                                style="width: 60%" placeholder="选择提醒日期" multiple :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
        <div slot="footer" v-loading="sta.loading">
            <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
            <el-button icon="el-icon-check" type="success" @click="submit" v-if="model.STATUS!=2">确认</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "edit",
        props: ['teacherList'],
        components: {  },
        data() {
            return {
                model: {
                    TEACHER_IDS: [],
                    DATES: [],
                    CONTENT: "{#老师姓名#}老师您好,{#提醒日期#}是您值日,请您按时到岗。",
                    TIME: "",
                },
                pickerOptions: {
                    disabledDate(time) {
                        // console.log(time.getDate())
                        return time.getTime() < Date.now();
                    },
                },
                sta: {
                    show: false,
                    loading: false
                },
                info: {},
                //status: [{ Id: 0, Name: '无需整改' }, { Id: 1, Name: '待整改' }, { Id: 2, Name: '已整改' }],

                // teacherList: [],


            }
        },
        created() {
            // this.getTeachers()
        },
        methods: {
            init() {
                this.sta = { show: true, loading: false }
            },


            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    data: { isPT: false },
                    completed: function (its) {
                        self.teacherList = its.DATA;
                    }
                })
            },

            submit() {
                let self = this
                if (self.model.TEACHER_IDS.length == 0) {
                    self.whale.toast.warn("请选择老师")
                    return
                }
                if (!self.model.DATES) {
                    self.whale.toast.warn("请选择日期")
                    return
                }


                console.log(self.model)
                // console.log(self.model.TYPE_ID)



                self.whale.remote.getResult({
                    url: "/api/School/FX/RemindApi/CreateNew",
                    data: self.model,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })

            },

        },
        watch: {
        },
    }
</script>

<style scoped>
    .label1 {
        width: 6em;
        text-align: right;
        margin-right: 5px;
        /* float: left; */
    }

    .sign .sign-tit {
        padding: 16px 16px 8px;
        color: #969799;
        font-size: 14px;
        line-height: 16px;
    }

    .sign .sign-canvas {
        /* display: block; */
        /* margin-left: 50px; */
    }

    .sign img {
        display: block;
        margin: 0 auto;
        width: 300px;
        height: 120px;
    }

    .sign .btns {
        margin-bottom: 10px;
        margin-left: 150px;
        /* text-align: left; */
    }
</style>