<template>
    <el-dialog :visible.sync="sta.show" width="750px">
        <span slot="title">查看 提醒记录</span>
        <div class="row">
            <div class="col-md-12">
                <el-row>
                    <el-col :span="24">
                        <el-calendar v-model="value" @input="dateChange">
                            <template slot="dateCell" slot-scope="{date, data}">
                                <p>{{ parseInt(data.day.substring(data.day.length - 2)) }}</p>
                                <div class="names">
                                    <span v-for="(t,index) in teacherList" :key="index">
                                        <template v-if="t.DAY==data.day">{{ t.TEACHERS.join(',') }}</template>
                                    </span>
                                </div>
                            </template>
                        </el-calendar>
                    </el-col>
                </el-row>

            </div>
        </div>
        <div slot="footer" v-loading="sta.loading">
            <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
        </div>
    </el-dialog>

</template>

<script>
    export default {
        name: "edit",
        components: {},
        data() {
            let dt = new Date()
            return {
                value: new Date(),
                sta: {
                    show: false,
                    loading: false
                },
                teacherList: [],
                year: dt.getFullYear(),
                month: dt.getMonth() + 1,
            }
        },
        created() {
            this.getMonthTeacehr()
        },
        methods: {
            init(isChange) {
                this.sta = { show: true, loading: false }
                if (isChange)
                    this.getMonthTeacehr()
            },

            getMonthTeacehr() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/FX/RemindApi/GetMonthTeacehr",
                    data: { year: this.year, month: this.month },
                    completed: function (its) {
                        self.teacherList = its.DATA;
                    }
                })
            },
            dateChange(val) {
                this.year = val.getFullYear()
                this.month = val.getMonth() + 1
                this.getMonthTeacehr()
            }

        },
        watch: {},
    }
</script>

<style>
    .el-calendar-table .el-calendar-day {
        min-height: 85px;
        height: auto;
    }
</style>